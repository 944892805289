import { Modal } from "@shared/ui";
import React, { useState, useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SelectPointModal } from "./SelectPointModal";

export const PointsList = ({ name, label }) => {
  const { control } = useFormContext();
  const [isModalOpen, setisModalOpen] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null); // Для стилей перетаскиваемого элемента
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  });

  const dragItemIndex = useRef(null);
  const dragOverItemIndex = useRef(null);

  const handleAddPoint = (point) => {
    append(point);
  };

  const handleRemovePoint = (index) => {
    remove(index);
  };

  const handleOpenModal = () => {
    setisModalOpen(true);
  };

  const handleCloseModal = () => {
    setisModalOpen(false);
  };

  const handleDragStart = (e, index) => {
    dragItemIndex.current = index;
    setDraggingIndex(index); // Устанавливаем индекс перетаскиваемого элемента
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
    dragOverItemIndex.current = index;
  };

  const handleDragEnd = () => {
    if (
      dragItemIndex.current !== null &&
      dragOverItemIndex.current !== null &&
      dragItemIndex.current !== dragOverItemIndex.current
    ) {
      move(dragItemIndex.current, dragOverItemIndex.current);
    }

    dragItemIndex.current = null;
    dragOverItemIndex.current = null;
    setDraggingIndex(null); // Сбрасываем стиль после завершения перетаскивания
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="border border-gray-300 rounded-lg p-5  box-border">
      {label && (
        <h3 className="mb-4 text-lg font-semibold text-center text-gray-800">
          {label}
        </h3>
      )}
      <div className="flex flex-col gap-4 items-stretch">
        {fields.length > 0 ? (
          <div className="flex flex-col gap-2 transition-all duration-200">
            {fields.map((field, index) => {
              const isDragging = draggingIndex === index; // Проверка, перетаскивается ли элемент
              const isDragOver =
                dragOverItemIndex.current === index &&
                dragItemIndex.current !== null;

              return (
                <div
                  key={field.id}
                  className={`flex items-center justify-between bg-white border border-gray-300 rounded-md p-2 transition-all duration-200 ${
                    isDragging
                      ? "bg-green-200 scale-105 shadow-lg cursor-move"
                      : ""
                  } ${isDragOver ? "bg-green-100 border-green-600" : ""}`}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={handleDragOver}
                  onDragEnd={handleDragEnd}
                >
                  <div>
                    <p className="text-sm text-gray-700 m-0">
                      Название: {field.label}
                    </p>
                    <p className="text-sm text-gray-700 m-0">
                      Адрес: {field.props.address || "Не указан"}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="bg-red-600 text-white text-sm py-1 px-3 rounded hover:bg-red-500 transition"
                    onClick={() => handleRemovePoint(index)}
                  >
                    Удалить
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-gray-600 text-sm text-center">Нет элементов</p>
        )}

        <button
          type="button"
          className="bg-green-600 text-white text-sm py-2 px-4 rounded hover:bg-green-500 transition self-center"
          onClick={handleOpenModal}
        >
          Добавить
        </button>
      </div>
      <SelectPointModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        append={handleAddPoint}
      />
    </div>
  );
};
