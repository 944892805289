import React, { useEffect, useState } from "react";
import image from "../images/bgWay.jpg";
import { Outlet, useParams, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, isFavorite, removeFavorite } from "../store/way";
import * as transform from "../utils/transformField";
import { BookmarkOn, BookmarkWhite, pdfIcon } from "../images";
import { routes } from "../routes";
import { Badge, Divider } from "../components/ui";
import { Bookmark, Container, Navigation } from "../components";

import { transformDuration } from "../utils/transformDuration";
import { Link } from "react-router-dom";
import { CustomButton } from "../components/ui/form/customButton";
import {
  useGetRoutesRegionsByIdQuery,
  useGetRoutesTypesByIdQuery,
  useGetRoutesOriginByIdQuery,
  useGetRouteByIdQuery,
  useLazyGetRoutesRegionsByIdQuery,
  useLazyGetRoutesTypesByIdQuery,
  useLazyGetRoutesOriginByIdQuery,
} from "../store/RTKApi/routes";

const WayPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = routes
    .find((r) => r.path === "/ways")
    .children.find((r) => r.path === ":id").children;

  const [wayData, setWayData] = useState({});

  const { data: way, isSuccess } = useGetRouteByIdQuery(id);

  const [getRoutesRegionsById, { data: region }] =
    useLazyGetRoutesRegionsByIdQuery();
  const [getRoutesTypesById, { data: type }] = useLazyGetRoutesTypesByIdQuery();
  const [getRoutesOriginById, { data: origin }] =
    useLazyGetRoutesOriginByIdQuery();

  useEffect(() => {
    if (way) {
      getRoutesRegionsById(way.region);
      getRoutesTypesById(way.type);
      getRoutesOriginById(way.origin);
    }
  }, [way]);
  const isAuthorized = useSelector((state) => state.auth.auth);

  const favorite = useSelector(isFavorite(+id));

  const handleClick = (id) => {
    !favorite ? dispatch(addFavorite(id)) : dispatch(removeFavorite(id));
  };

  return (
    isSuccess && (
      <>
        <Container>
          <section
            style={{
              backgroundImage: `linear-gradient(rgba(1, 1, 1, 0.1), rgba(0, 0, 0, 0.6)), url(${way.poster})`,
            }}
            className="mt-20 md:h-[350px] w-full bg-no-repeat bg-cover  py-9 mb-0 bg-center"
          >
            <div className="flex  flex-col h-full gap-4 px-5">
              <div className="flex-grow  flex flex-col justify-center gap-1">
                <div className=" flex justify-start gap-2 items-start">
                  <h2 className="text-white   flex-grow  text-3xl font-bold">
                    {way.title}
                  </h2>
                  <div className="flex flex-col ">
                    <CustomButton
                      label={"Назад"}
                      styles={
                        "  bg-text-primary-green hover:bg-text-secondary-green disabled:opacity-60 px-2 !h-[30px]"
                      }
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    {isAuthorized ? (
                      <CustomButton
                        styles={
                          "  bg-text-primary-green hover:bg-text-secondary-green px-4  md:!h-[30px]"
                        }
                        onClick={() =>
                          navigate(`/account/events/create`, {
                            state: { id: way?.id },
                          })
                        }
                      >
                        Выбрать маршрут
                      </CustomButton>
                    ) : null}
                  </div>
                </div>

                <p className="text-white   max-w-fit ">
                  {way.short_description
                    .slice(0, -3)
                    .slice(0, way.short_description.lastIndexOf(". ") + 1)}
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:justify-between gap-4">
                {" "}
                <div className="flex gap-2 flex-wrap md:flex-nowrap">
                  <Badge
                    styles={" h-[30px] border-0 max-w-[350px] bg-[#EBEBEB]"}
                    textstyle={" text-black text-[12px]"}
                    label={origin?.title}
                  />
                  <Badge
                    styles={" h-[30px] border-[#C4C4C4] bg-white"}
                    textstyle={" text-text-secondary-green"}
                    label={region?.title}
                  />
                  <Badge
                    styles={" h-[30px]  border-[#55BFFA]"}
                    label={transformDuration(way.duration)}
                    textstyle={" text-white"}
                  />
                  <Badge
                    styles={" h-[30px]  border-[#55BFFA]"}
                    label={transform.transformDistance(way.length)}
                    textstyle={" text-white"}
                  />
                  <Badge
                    styles={` h-[30px] border-0`}
                    label={type?.title}
                    type={type?.id}
                    textstyle={" text-white"}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <Bookmark
                    onClick={() => handleClick(+id)}
                    icon={!favorite ? BookmarkWhite : BookmarkOn}
                  />
                  <div className="flex gap-2 items-center">
                    <img src={pdfIcon} alt="Скачать PDF" />
                    <Link
                      to={way?.file}
                      className=" leading-[21px] font-normal text-[14px]  text-white uppercase hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Маршрут
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
        <Container>
          <div className="navigation mt-5 ">
            <Navigation
              routes={route}
              styles={" overflow-auto flex-nowrap flex"}
            />
          </div>

          <Divider />

          <Outlet
            context={{
              id,
              attractions: way?.attractions,
              description: way.description,
              sources: way.sources,
              region,
              duration: way.duration,
              type,
              length: way.length,
              points: way.points,
              medias: way.medias,
            }}
          />
        </Container>
      </>
    )
  );
};

export default WayPage;
