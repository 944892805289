import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export function TextEditor({ className, placeholder, name, onChange, value }) {
  const editorRef = useRef(null);

  useEffect(() => {
    const toolbarOptions = [
      [{ size: [] }], // Dropdown with font and size options
      ["bold", "italic", "underline", "strike"], // Toggle buttons for bold, italic, underline, and strike-through
      [{ list: "ordered" }, { list: "bullet" }], // Lists: ordered and bullet
      [{ align: [] }], // Dropdown with alignment options

      [{ script: "sub" }, { script: "super" }], // Superscript / subscript
      [{ header: "1" }, { header: "2" }], // Custom button values for headers
      [{ color: [] }, { background: [] }], // Dropdown with color and background color options
      ["clean"], // Remove formatting button
    ];

    const quill = new Quill(editorRef.current, {
      theme: "snow",
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder,
    });
    quill.on("text-change", (e) => {
      onChange({
        target: {
          name,
          value: quill.root.innerHTML,
        },
      });
    });

    return () => {
      if (editorRef.current) {
        editorRef.current.off("text-change");
        editorRef.current = null;
      }
    };
  }, [name, onChange, placeholder]);

  return (
    <div className={className}>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        style={{ display: "none" }} // Hide this textarea, it's only for controlled updates
      />
      <div ref={editorRef} style={{ height: "200px" }} />
    </div>
  );
}
