import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config.json";
import Rtk_utils from "../../utils/rtk_utils";

import { getAccessToken } from "@entities/auth";

export const outDoorEventsApi = createApi({
  reducerPath: "outDoorEventsApi",
  tagTypes: ["Event", "Routes"],
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl + "/outdoor-events/",
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=`
      );
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    addRoute: build.mutation({
      query: (body) => ({
        url: "/create-route/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Routes"],
    }),
    addNewOutDoorEvent: build.mutation({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    patchStatusOutDoorEvent: build.mutation({
      query: (args) => {
        const { id, body } = args;
        return {
          url: `${id}/ch-status/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Event"],
    }),
    patchOutDoorEvent: build.mutation({
      query: (args) => {
        const { id, body } = args;
        return {
          url: `${id}/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Event"],
    }),
    deleteOutDoorEvent: build.mutation({
      query: (args) => {
        const { id } = args;
        return {
          url: `${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Event"],
    }),
    getOutDoorEventsDocs: build.query({
      query: (id) => `${id}/docs/`,
    }),
    getOutDoorEventsById: build.query({
      query: (id) => `${id}`,
    }),
    getAllOutdoorsNotes: build.query({
      query: (args) => {
        const queryString = Rtk_utils.ClearQueryString(args);
        return {
          url: `notes/${queryString}`,
          method: "GET",
        };
      },
    }),
    getOutdoorsNoteById: build.query({
      query: (id) => {
        return {
          url: `notes/${id}`,
          method: "GET",
        };
      },
    }),
    getAllOutdoorsEvents: build.query({
      query: (args) => {
        const queryString = Rtk_utils.ClearQueryString(args);
        return {
          url: `${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Event"],
    }),
    getOutDoorEventsForm: build.query({
      query: () => `event_forms/`,
    }),
    addOutDoorEventsForm: build.mutation({
      query: (body) => ({
        url: "event_forms/",
        method: "POST",
        body,
      }),
    }),
    getOutDoorEventsFormById: build.query({
      query: (id) => `${id}`,
      providesTags: ["Event"],
    }),
    putOutDoorEventsFormById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `event_forms/${id}`,
        method: "PUT",
        body,
      }),
    }),
    patchOutDoorEventsFormById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `event_forms/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteOutDoorEventsFormById: build.mutation({
      query: (id) => ({
        url: `event_forms/${id}`,
        method: "DELETE",
      }),
    }),
    getOutDoorEventsTypes: build.query({
      query: () => `event_types/`,
    }),
    addOutDoorEventsTypes: build.mutation({
      query: (body) => ({
        url: "event_types/",
        method: "POST",
        body,
      }),
    }),
    getOutDoorEventsTypesById: build.query({
      query: (id) => `event_types/${id}`,
    }),
    putOutDoorEventsTypesById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `event_types/${id}`,
        method: "PUT",
        body,
      }),
    }),
    patchOutDoorEventsTypesById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `event_types/${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteOutDoorEventsTypesById: build.mutation({
      query: (id) => ({
        url: `event_types/${id}`,
        method: "DELETE",
      }),
    }),
    getOutDoorEventsSchoolById: build.query({
      query: (id) => `school/${id}`,
    }),
    getOutDoorEventsToVerify: build.query({
      query: () => `to-verify/`,
    }),
    addOutDoorEventsVerify: build.mutation({
      query: (body) => ({
        url: "verify/",
        method: "POST",
        body,
      }),
    }),
    putOutDoorEventsUpdateById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `update/${id}`,
        method: "PUT",
        body,
      }),
    }),
    patchOutDoorEventsUpdateById: build.mutation({
      query: ({ id, ...body }) => ({
        url: `update/${id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  //Get
  useGetOutDoorEventsFormQuery,
  useGetOutDoorEventsFormByIdQuery,
  useGetOutDoorEventsTypesQuery,
  useGetOutDoorEventsTypesByIdQuery,
  useGetOutDoorEventsSchoolByIdQuery,
  useGetOutDoorEventsToVerifyQuery,
  useGetAllOutdoorsNotesQuery,
  useGetAllOutdoorsEventsQuery,
  useLazyGetAllOutdoorsEventsQuery,
  useGetOutDoorEventsDocsQuery,
  useGetOutdoorsNoteByIdQuery,
  useLazyGetOutDoorEventsByIdQuery,
  useGetOutDoorEventsByIdQuery,
  //Post
  useAddNewOutDoorEventMutation,
  useAddOutDoorEventsFormMutation,
  useAddOutDoorEventsTypesMutation,
  useAddOutDoorEventsVerifyMutation,
  useAddRouteMutation,
  //Put
  usePutOutDoorEventsFormByIdMutation,
  usePutOutDoorEventsTypesByIdMutation,
  usePutOutDoorEventsUpdateByIdMutation,
  //Patch
  usePatchOutDoorEventsFormByIdMutation,
  usePatchOutDoorEventsTypesByIdMutation,
  usePatchOutDoorEventsUpdateByIdMutation,
  usePatchStatusOutDoorEventMutation,
  usePatchOutDoorEventMutation,
  //delete
  useDeleteOutDoorEventsFormByIdMutation,
  useDeleteOutDoorEventMutation,
  useDeleteOutDoorEventsTypesByIdMutation,
} = outDoorEventsApi;
