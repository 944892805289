/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Burger } from "./ui";
import { BookmarkWhite } from "../images";
import { Bookmark } from "./bookmark";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useLockBodyScroll } from "@uidotdev/usehooks";
import { AuthIcon } from "./auth";

const Modal = ({ open, children }) => {
  useLockBodyScroll();

  return (
    <div
      className={
        "fixed -top-[200%]  duration-300 left-0 bg-black bg-opacity-90 w-full h-screen justify-start items-center gap-6 md:hidden " +
        (open && "!top-0")
      }
    >
      {children}
    </div>
  );
};

export const Navigation = React.forwardRef(
  ({ routes, open, setOpen, styles, header }, ref) => {
    const [subWaysIsOpen, setSubWaysIsOpen] = useState(false);

    const handleOpenSub = () => {
      setSubWaysIsOpen((prev) => !prev);
    };

    return (
      <>
        <nav
          className={
            " justify-start items-center gap-6 md:flex pr-4 md:pr-0 lg:pr-4  " +
            styles
          }
        >
          {routes.map((route) => {
            if (route?.path !== "/ways") {
              if (route?.display === true) {
                return (
                  <NavLink
                    target={route.path.includes("https") ? "_blank" : "_self"}
                    to={route.path}
                    key={route.path}
                    end
                    className={header ? "nav-link-header" : "nav-link"}
                  >
                    {route.title}
                  </NavLink>
                );
              } else {
                return null;
              }
            } else {
              return (
                <div
                  key={route.path}
                  className={" relative cursor-pointer "}
                  onMouseEnter={() => setSubWaysIsOpen(true)}
                  onMouseLeave={() => setSubWaysIsOpen(false)}
                >
                  <div className=" flex justify-between items-center gap-2">
                    {" "}
                    <span className="text-text-primary font-rubik font-normal text-sm hover:opacity-80   transition duration-300 ease-out whitespace-nowrap  nav-link-header">
                      {route.title}
                    </span>
                    <ChevronDownIcon
                      className={
                        " w-6 h-6 trasition duration-300 text-white" +
                        (subWaysIsOpen ? " rotate-180" : "")
                      }
                    />
                  </div>

                  <>
                    <div
                      className={
                        " absolute w-full bg-text-secondary-green-green pt-[27px] lg:pt-[28px]  z-10 transition-all duration-300 " +
                        (subWaysIsOpen
                          ? " opacity-1"
                          : "  opacity-0 invisible delay-300 ")
                      }
                    >
                      <div className=" top-full bg-white left-0 w-full border-t-2 p-5 rounded-b-md border-green-500 shadow-md flex flex-col  gap-5 ">
                        <NavLink
                          to={"/ways"}
                          className={
                            "!text-text-secondary-green nav-link-header"
                          }
                        >
                          Маршруты
                        </NavLink>
                      </div>
                    </div>
                  </>
                </div>
              );
            }
          })}
        </nav>

        {open && (
          <Modal open={open}>
            <nav>
              <div className=" flex w-full justify-center">
                {" "}
                <div className=" flex justify-between py-5 items-center container">
                  <div className=" flex justify-start items-center pl-4">
                    <AuthIcon setOpen={setOpen} />
                    <Link
                      to="/favorites"
                      className=" pl-4"
                      onClick={() => {
                        setOpen((prev) => !prev);
                      }}
                    >
                      <Bookmark icon={BookmarkWhite} nav={true} />
                    </Link>
                  </div>

                  <Burger open={open} setOpen={setOpen} />
                </div>
              </div>

              <div className=" flex flex-col gap-8 p-4 ">
                {routes.map((route) => {
                  if (route?.path !== "/ways") {
                    if (
                      route?.display === true &&
                      route?.path !== "/favorites" &&
                      route?.path !== "/account"
                    ) {
                      return (
                        <NavLink
                          to={route.path}
                          key={route.path}
                          className={" nav-link !text-white"}
                          onClick={() => setOpen((prev) => !prev)}
                        >
                          {route.title}
                        </NavLink>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <div key={route.path}>
                        <div
                          className=" flex  items-center gap-2"
                          onClick={handleOpenSub}
                        >
                          {" "}
                          <span className="text-white font-rubik font-normal text-sm hover:opacity-80   transition duration-300 ease-out whitespace-nowrap ">
                            {route.title}
                          </span>
                          <ChevronDownIcon
                            className={
                              " w-6 h-6 trasition duration-300  fill-white" +
                              (subWaysIsOpen ? " rotate-180" : "")
                            }
                          />
                        </div>
                        {subWaysIsOpen && (
                          <>
                            <div className="  top-full left-0 w-full   p-5  flex flex-col  gap-5 pb-0 ">
                              <NavLink
                                to={"/ways"}
                                className={" nav-link !text-white"}
                                onClick={() => setOpen((prev) => !prev)}
                              >
                                Маршруты
                              </NavLink>
                              {/* <NavLink
                                to={"/notes"}
                                className={" nav-link !text-white"}
                                onClick={() => setOpen((prev) => !prev)}
                              >
                                Путевые заметки
                              </NavLink> */}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </nav>
          </Modal>
        )}
      </>
    );
  }
);
