import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "@shared/ui";
import { useRouteFormData } from "../hooks/useRouteFormData";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import { FixedSizeGrid as Grid } from "react-window";

export const SelectPointModal = ({ isModalOpen, handleCloseModal, append }) => {
  const { points } = useRouteFormData();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [selected, setSelected] = useState(null);

  // Создаём мемоизированную версию debounce-функции, чтобы она не пересоздавалась при каждом рендере
  const debouncedUpdate = useMemo(
    () =>
      debounce((value) => {
        setDebouncedTerm(value);
      }, 300),
    []
  );

  // Каждый раз, когда searchTerm меняется, вызываем debouncedUpdate
  useEffect(() => {
    debouncedUpdate(searchTerm);
  }, [searchTerm, debouncedUpdate]);

  // Фильтруем точки по debouncedTerm
  const filteredPoints = points.filter(
    (point) =>
      point.label.toLowerCase().includes(debouncedTerm.toLowerCase()) ||
      point.props.address?.toLowerCase().includes(debouncedTerm.toLowerCase())
  );

  const handleClick = () => {
    handleCloseModal();
    setSelected(null);
    if (selected) append(selected);
  };

  // Рендер элемента для сетки
  const renderCell = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 3 + columnIndex; // Рассчитываем индекс элемента
    if (index >= filteredPoints.length) return null; // Проверяем границы массива

    const point = filteredPoints[index];
    return (
      <div
        key={point.value}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center", // Центрируем содержимое
          alignItems: "center",
          padding: "2px", // Отступы внутри ячейки
        }}
      >
        <div
          className={
            "border cursor-pointer border-gray-300 rounded-lg p-5 " +
            (selected?.value === point.value ? "bg-green-200" : "")
          }
          style={{
            width: "100%", // Занимаем всю ширину ячейки
            height: "100%", // Занимаем всю высоту ячейки
            boxSizing: "border-box", // Учитываем padding
          }}
          onClick={() =>
            setSelected(selected?.value === point.value ? null : point)
          }
        >
          <h4>Название: {point.label}</h4>
          <p>Адрес: {point.props.address ?? "не указан"}</p>
          <p className="truncate">Описание: {point.props.description}</p>
          <p>
            Координаты: {point.props.longitude}, {point.props.latitude}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <TextField
        label="Поиск по названию или адресу"
        className="!mb-4 w-1/3"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Grid
        className="min-w-max max-h-96 overflow-y-auto"
        columnCount={3} // Количество столбцов
        columnWidth={316} // Ширина элемента (300px + 2 * 8px padding + 2 * 4px margin)
        height={400} // Высота контейнера
        rowCount={Math.ceil(filteredPoints.length / 3)} // Количество строк
        rowHeight={200} // Высота элемента (200px + 2 * 8px padding + 2 * 4px margin)
        width={936} // Общая ширина контейнера (3 * 316px)
      >
        {renderCell}
      </Grid>

      <div className="flex gap-2 justify-end mt-8 text-white">
        <button
          className="bg-red-500 text-sm py-1 px-3 rounded hover:bg-gray-400 transition"
          onClick={handleCloseModal}
          type="button"
        >
          Отмена
        </button>
        <button
          className={
            "bg-text-primary-green text-sm py-1 px-3 rounded hover:bg-green-600 transition disabled:bg-slate-500"
          }
          onClick={handleClick}
          type="button"
          disabled={!selected}
        >
          Выбрать
        </button>
      </div>
    </Modal>
  );
};
