import { Controller, useFormContext } from "react-hook-form";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const ControlledSelect = ({
  name,
  label,
  options,
  multiple = false,
  ...selectProps
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth className={selectProps.className}>
          <InputLabel id={`${name}_label`}>{label}</InputLabel>
          <Select
            multiple={multiple}
            labelId={`${name}_label`}
            label={label}
            {...field}
            {...selectProps}
          >
            {options.map(({ value, label }) => {
              return (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};
