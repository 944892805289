import {
  useGetRoutesSeasonsQuery,
  useGetRoutesRegionsQuery,
  useGetRoutesTargetAudiencesQuery,
  useGetRoutesAgeCategoriesQuery,
  useGetRoutesTypesQuery,
  useGetRoutesAttractionsQuery,
  useGetRoutesNaturalEnvironmentsQuery,
  useGetRoutesTagsQuery,
} from "@store/RTKApi/routes";
import {
  useGetRoutesNodesQuery,
  useGetRoutesOriginQuery,
} from "store/RTKApi/routes";
import { mappedData } from "utils/mappedData";

export const useRouteFormData = () => {
  const { data: seasons, isSuccess: seasonSuccess } =
    useGetRoutesSeasonsQuery();
  const { data: regions, isSuccess: regionSuccess } =
    useGetRoutesRegionsQuery();
  const { data: audience, isSuccess: audienceSuccess } =
    useGetRoutesTargetAudiencesQuery();
  const { data: age, isSuccess: ageSuccess } = useGetRoutesAgeCategoriesQuery();
  const { data: type, isSuccess: typeSuccess } = useGetRoutesTypesQuery();
  const { data: attractions, isSuccess: attractionsSuccess } =
    useGetRoutesAttractionsQuery();
  const { data: naturalEnvironment, isSuccess: naturalEnvironmentSuccess } =
    useGetRoutesNaturalEnvironmentsQuery();
  const { data: tags, isSuccess: tagsSuccess } = useGetRoutesTagsQuery();
  const {
    data: attractionsFromOrigin,
    isSuccess: attractionsSuccessFromOrigin,
  } = useGetRoutesOriginQuery();

  const { data: nodeData, isSuccess: nodeSuccess } = useGetRoutesNodesQuery({});

  // Пример преобразования данных достопримечательностей

  const returnend = {
    seasons: seasonSuccess ? seasons : [],
    regions: regionSuccess ? regions : [],
    audience: audienceSuccess ? audience : [],
    ageCategories: ageSuccess ? age : [],
    types: typeSuccess ? type : [],
    attractions: attractionsSuccess ? attractions : [],
    naturalEnvironment: naturalEnvironmentSuccess ? naturalEnvironment : [],
    tags: tagsSuccess ? tags : [],
    origins: attractionsSuccessFromOrigin ? attractionsFromOrigin : [],
    points: nodeSuccess ? nodeData : [],
  };

  return Object.entries(returnend).reduce((acc, [key, value]) => {
    acc[key] = mappedData(value);
    return acc;
  }, {});
};
