import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";

export const ControlledTextField = ({
  name,
  label,
  className,
  variant = "outlined",
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          className={className}
          variant={variant}
          {...props}
        />
      )}
    />
  );
};
