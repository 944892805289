import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { CustomButton } from "@components";

export const SourcesInputList = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sources",
  });

  return (
    <>
      {fields.map((el, index) => (
        <div className="grid grid-cols-3 gap-5" key={index}>
          <Controller
            name={`sources.${index}`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Источники литературы"
                placeholder="Введите ссылку или название"
                variant="outlined"
                fullWidth
                className="col-span-2"
              />
            )}
          />
          {index === fields.length - 1 ? (
            <CustomButton
              styles={
                "col-span-1 h-full bg-text-primary-green !m-0  max-w-[170px]"
              }
              label="Добавить"
              type="button"
              onClick={() => append(" ")}
            >
              Добавить
            </CustomButton>
          ) : (
            <CustomButton
              styles={"col-span-1 h-full bg-text-gray !m-0 max-w-[170px]"}
              label="Удалить"
              type="button"
              onClick={() => remove(index)}
            >
              Удалить
            </CustomButton>
          )}
        </div>
      ))}
    </>
  );
};
