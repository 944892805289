import { useRouteFormData } from "./hooks/useRouteFormData";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Autocomplete, Divider, TextField } from "@mui/material";

import {
  ControlledSelect,
  ControlledTextField,
  CustomButton,
} from "@components";
import { PointsList, SourcesInputList } from "./components";
import { TextEditor } from "@shared/widgets";
import { FileUpload } from "@features";
import { DropzonePreview } from "@features/index";
import { useAddRouteMutation } from "store/RTKApi/outdoorEvents";

export const AddRouteForm = () => {
  const {
    seasons,
    regions,
    audience,
    ageCategories,
    types,
    attractions,
    naturalEnvironment,
    tags,
    origins,
  } = useRouteFormData();

  const [trigger, { isSuccess, isLoading }] = useAddRouteMutation();

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      title: "",
      season: [],
      region: "",
      target_audience: [],
      age_category: [],
      natural_environments: "",
      author: "",
      tags: [],
      description: "",
      short_description: "",
      sources: [" "],
      origin: "",
      media_files: [],
      durationDays: "",
      durationHours: "",
      type: "",
      file: null,
      length: "",
      attractions: [],
      poster: null,
      points: [],
    },
  });

  const onSubmit = (data) => {
    console.log("Form data:", data);
    trigger(data);
    // Логика отправки данных
  };

  return (
    <section>
      <h2 className="text-2xl font-bold">Создание маршрута</h2>
      <FormProvider control={control} watch={watch} setValue={setValue}>
        <form
          className="mt-10 flex flex-col gap-7"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DropzonePreview name="poster" />
          <div className="grid grid-cols-3 gap-5">
            <ControlledTextField
              name="title"
              className="col-span-2"
              label="Название"
            />
          </div>

          <div className="grid grid-cols-3 gap-5">
            <ControlledSelect
              name={"season"}
              label={"Сезон"}
              options={seasons}
              multiple
            />
            <ControlledSelect
              name={"region"}
              label={"Регион"}
              options={regions}
            />
          </div>
          <div className="grid grid-cols-3 gap-5">
            <ControlledSelect
              options={audience}
              label="Целевая аудитория"
              name="target_audience"
              multiple
            />
            <ControlledSelect
              options={ageCategories}
              label="Возраст обучающихся"
              name="target_audience"
              multiple
            />
          </div>
          <div className="flex gap-5">
            <span className="font-bold">
              Продолжительность <br /> маршрута
            </span>
            <ControlledTextField name="durationDays" label="Дни" />
            <ControlledTextField name="durationHours" label="Часы" />
          </div>
          <div className="grid grid-cols-3 gap-5">
            <ControlledSelect
              options={types}
              label="Вид маршрута"
              name="type"
            />
            <ControlledTextField name="length" label="Протяженность маршрута" />
          </div>
          <div className="grid grid-cols-3 gap-5">
            <Controller
              name="attractions"
              render={({ field }) => (
                <Autocomplete
                  multiple
                  clearOnBlur
                  options={attractions.sort(
                    (a, b) => -b.label[0].localeCompare(a.label[0])
                  )} // Сортируем по первой букве label
                  groupBy={(option) => option.label[0].toUpperCase()} // Группируем по первой букве label
                  getOptionLabel={(option) => option.label}
                  value={attractions.filter((opt) =>
                    field.value.includes(opt.value)
                  )}
                  onChange={(_, value) =>
                    field.onChange(value.map((v) => v.value))
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Объекты на маршруте"
                      placeholder="Объекты на маршруте"
                    />
                  )}
                />
              )}
            />
            <ControlledSelect
              options={naturalEnvironment}
              label="Природная среда"
              name="natural_environments"
            />
          </div>

          <div className="grid grid-cols-3 gap-5">
            <Divider className="col-span-2" />
          </div>

          <div className="grid grid-cols-3 gap-5">
            <ControlledTextField name="author" label="Автор" />
            <ControlledSelect
              options={tags}
              label="Ключевые направления"
              name="tags"
              multiple
            />
          </div>
          <div className="grid grid-cols-3 gap-5 ">
            <Controller
              name="description"
              control={control}
              render={({ field: { ref, ...fieldProps } }) => (
                <TextEditor
                  placeholder="Описание маршрута"
                  className={"col-span-2"}
                  {...fieldProps}
                />
              )}
            />
            <ControlledTextField
              multiline={true}
              rows={4}
              name="short_description"
              label="Краткое описание"
              className="col-span-2 "
            />
          </div>

          <SourcesInputList />

          <div className="grid grid-cols-3 gap-5">
            <ControlledSelect
              options={origins}
              label="Организация-автор маршрута"
              name="origin"
              className="col-span-2"
            />
          </div>

          <Divider />

          <FileUpload name="media_files" multiple label="Медиа-файлы" />
          <FileUpload name="file" label="Загрузите pdf" />

          <Divider />

          <PointsList name="points" label="Точки маршрута" />

          <CustomButton
            styles={" min-w-[150px]  w-[50px] h-12 !mt-4 bg-text-primary-green"}
            label="Отправить"
            type="submit"
          >
            Отправить
          </CustomButton>
        </form>
      </FormProvider>
    </section>
  );
};
