import React, { memo, useRef, useState } from "react";
import styles from "./Field.module.scss";
import { Icon } from "@shared/ui";

export const Field = memo(({ icon, label, onChange, type, value, name }) => {
  const inputRef = useRef < HTMLInputElement > null;
  const [visible, setVisible] = useState(false);

  const handleToogleClick = () => {
    setVisible((prev) => !prev);
  };
  return (
    <div
      className={styles.field}
      onClick={() => {
        if (inputRef.current) inputRef.current.focus();
      }}
    >
      {icon && (
        <Icon
          icon={icon}
          bgColor="inherit"
          stroke="none"
          fill="none"
          size={{ height: 24, width: 24 }}
          scale="80%"
        />
      )}
      <div className={styles[`field__input--wrapper`]}>
        <input
          className={styles.field__input}
          name={name}
          type={visible ? "text" : type}
          placeholder={label}
          onChange={onChange}
          ref={inputRef}
          value={value}
        />
        {type === "password" && (
          <div className={styles.icon} onClick={handleToogleClick}>
            <Icon
              icon={visible ? "eyeOff" : "eye"}
              size={{ height: 24, width: 24 }}
              bgColor="inherit"
              scale="100%"
            />
          </div>
        )}
      </div>
    </div>
  );
});
