import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { mcvpWhite, moscowEd, travelSchoolLogoWhite } from "../images";
import { routes } from "../routes";
import { Burger } from "./ui";

import { useResize } from "../hooks/useResize";

export const Header = React.memo(() => {
  // const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const [scrollAtTop, setScrollAtTop] = useState(true);

  const handleScroll = () => {
    const isAtTop = window.scrollY === 0;
    if (scrollAtTop !== isAtTop) {
      setScrollAtTop(isAtTop);
    }
  };

  useEffect(() => {
    // Используем throttle для оптимизации частоты вызовов
    const throttledHandleScroll = _.throttle(handleScroll, 300);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, [scrollAtTop]); // Зависимость scrollAtTop для обновления обработчика при изменении состояния

  const { isScreenLg } = useResize();

  const classes = " rounded-[5px] md:translate-y-4 md:container ";

  return (
    <header
      style={{
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",

        transition: "all 1s ease",
      }}
      className={
        (scrollAtTop && pathname === "/" && isScreenLg ? classes : "") +
        " fixed flex justify-center z-20 w-screen bg-[#168743] min-h-[80px]  md:left-1/2 md:-translate-x-1/2"
      }
    >
      <div
        className=" relative flex justify-between md:justify-center lg:justify-between items-center  flex-nowrap
container
 w-full  py-3 "
      >
        <div
          className={
            "flex justify-start gap-3 flex-grow items-center pl-4 md:hidden lg:flex"
          }
        >
          <Link
            className="w-24 md:w-1/2 lg:max-w-[105px] xl:max-w-[175px]"
            to="/"
          >
            <img
              src={travelSchoolLogoWhite}
              alt="Школа Москва"
              height={40}
              className="w-full"
            ></img>
          </Link>
          <a
            href={"https://shkolamoskva.ru/"}
            target={"_blank"}
            className="w-24 md:w-1/2 lg:max-w-[105px] xl:max-w-[125px] "
          >
            <img src={moscowEd} alt="Московское образование" />
          </a>
          <a
            href={"https://mcvp.ru/"}
            target={"_blank"}
            className="w-24 md:w-1/2 lg:max-w-[105px] xl:max-w-[175px]"
          >
            <img src={mcvpWhite} className="w-full" alt="МЦВП" />
          </a>
        </div>
        {!open && <Burger open={open} setOpen={setOpen} />}
        <Navigation
          routes={routes}
          open={open}
          setOpen={setOpen}
          styles={" hidden"}
          header={true}
        />
      </div>
    </header>
  );
});
