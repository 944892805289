import React, { useRef } from "react";
import styles from "./FileUpload.module.scss";
import { useFieldArray, useFormContext } from "react-hook-form";

export const FileUpload = ({ name, multiple = false, label }) => {
  const { control } = useFormContext();
  const { fields, append, replace, remove, move } = useFieldArray({
    control,
    name,
  });

  const dragItemIndex = useRef(null);
  const dragOverItemIndex = useRef(null);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) return;

    const newFiles = Array.from(selectedFiles).map((file) => ({ file }));

    if (multiple) {
      // Множественный режим: добавляем новые файлы в конец списка
      newFiles.forEach((item) => append(item));
    } else {
      // Одиночный режим: заменяем существующий файл новым
      replace(newFiles);
    }
  };

  const handleDragOverContainer = (event) => {
    event.preventDefault();
  };

  const handleDropContainer = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles || droppedFiles.length === 0) return;

    const newFiles = Array.from(droppedFiles).map((file) => ({ file }));
    if (multiple) {
      newFiles.forEach((item) => append(item));
    } else {
      replace(newFiles);
    }
  };

  const handleRemoveFile = (index) => {
    remove(index);
  };

  // Функции для Drag and Drop внутри списка (актуально только при multiple)
  const handleDragStart = (e, index) => {
    if (!multiple) return;
    dragItemIndex.current = index;
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnter = (e, index) => {
    if (!multiple) return;
    e.preventDefault();
    dragOverItemIndex.current = index;
  };

  const handleDragEnd = () => {
    if (!multiple) return;
    if (
      dragItemIndex.current !== null &&
      dragOverItemIndex.current !== null &&
      dragItemIndex.current !== dragOverItemIndex.current
    ) {
      move(dragItemIndex.current, dragOverItemIndex.current);
    }

    dragItemIndex.current = null;
    dragOverItemIndex.current = null;
  };

  const handleDragOver = (e) => {
    if (!multiple) return;
    e.preventDefault();
  };

  return (
    <div className={styles["file-upload-wrapper"]}>
      {label && <h3 className={styles["file-upload-title"]}>{label}</h3>}
      <div
        className={styles["file-upload"]}
        onDragOver={handleDragOverContainer}
        onDrop={handleDropContainer}
      >
        <input
          type="file"
          id={name}
          className={styles["file-input"]}
          onChange={handleFileChange}
          multiple={multiple}
        />
        <label htmlFor={name} className={styles["upload-label"]}>
          Загрузите {multiple ? "файлы" : "файл"}
        </label>
        <p className={styles["drag-text"]}>
          или перетащите {multiple ? "файлы" : "файл"} в эту область
        </p>

        {fields.length > 0 && (
          <div className={styles["file-list"]}>
            {fields.map((field, index) => {
              const file = field.file;
              const isImage = file?.type?.startsWith("image/");
              return (
                <div
                  key={field.id}
                  className={styles["file-item"]}
                  draggable={multiple} // Разрешаем перетаскивать, только если multiple = true
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={handleDragOver}
                  onDragEnd={handleDragEnd}
                >
                  {isImage && (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className={styles["file-preview"]}
                    />
                  )}
                  <p className={styles["file-name"]}>{file.name}</p>
                  <button
                    type="button"
                    className={styles["remove-button"]}
                    onClick={() => handleRemoveFile(index)}
                  >
                    Удалить
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
