import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import styles from "./Dropzone.module.scss";

export const DropzonePreview = ({ name, error, small }) => {
  const { setValue, watch } = useFormContext();
  const file = watch(name); // Слежение за значением поля (загруженным файлом)

  // Функция для загрузки картинки (имитация)

  // Функция удаления изображения (имитация)

  // Обновление значения в форме, когда файл меняется
  const onFileChange = useCallback(
    (file) => {
      setValue(name, file);
    },
    [setValue, name]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]; // Берем первый файл
      if (file) {
        // Загружаем изображение (имитация)
        onFileChange(file); // Обновляем значение в форме
      }
    },
    [onFileChange]
  );

  const handleRemoveImage = useCallback(() => {
    onFileChange(null); // Сбрасываем значение в форме
  }, [onFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [], // Принимаем любые изображения
    },
  });

  // Если есть файл, создаем превью (при условии, что это изображение)
  const preview = useMemo(() => {
    if (file && file.type?.startsWith("image/")) {
      return URL.createObjectURL(file);
    }
    return null;
  }, [file]);

  return (
    <div
      {...getRootProps({
        className: `${styles.dropzoneContainer} 
           ${small ? styles["dropzoneContainer--small"] : ""} 
           ${preview ? styles.fill : ""}`,
      })}
    >
      <input {...getInputProps()} />

      {/* Если есть preview, показываем его, иначе отображаем инструкцию */}
      {preview ? (
        <div className={styles["image-preview"]}>
          <img src={preview} alt="preview" className={styles.image} />
          <div className={styles.overlay}>
            <button
              className={styles["replace-button"]}
              onClick={handleRemoveImage}
              type="button"
            >
              Заменить
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.placeholder}>
          {isDragActive ? (
            <p>Отпустите файл, чтобы загрузить его</p>
          ) : (
            <div className={styles.flex}>
              <p>
                {error ? (
                  "Добавьте изображение"
                ) : (
                  <span>
                    Чтобы добавить изображение, <br /> нажмите или перетащите
                    сюда его
                  </span>
                )}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
